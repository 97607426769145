.home-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.name-input-field {
    width: 30rem;
}

.name-input-field :focus {
    border-color: coral !important;
    box-shadow: 0 0 0 0.125em coral !important;
}

.details-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

input {
    font-family: 'Fredoka One', cursive !important;
}

.play-button {
    font-family: 'Fredoka One', cursive !important;
    background-color: #f0466b !important;
    color: #fff !important;
}

.login-ttt-logo {
    width: 9rem
}

.footer-text {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
}

.sponsorship{
    text-align: center;
}

.bmc-logo{
    width: 9rem;
}

.login-button{
    margin-left: 1rem;
}

@media only screen and (max-width: 711px) {
    .name-input-field {
        width: 20rem;
    }

    .login-inputs {
        display: flex;
        flex-direction: column;
    }

    .login-button{
        margin-left: 0 !important;
    }

}
