.board-button {
    background-color: rgb(255, 255, 255) !important;
    border: 2px solid rgb(0, 0, 0);
	font-size: 40px;
	font-weight: 800;
	cursor: pointer;
	outline: none;
    font-family: 'Fredoka One', cursive !important;
    height: 7rem !important;
    width: 7rem !important
}
