/* Import Font */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
/* Import Font Awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css");
/* Import Animate CSS */
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');

body {
  margin: 0;
  font-family: 'Fredoka One', cursive !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swal2-confirm{
  font-family: 'Fredoka One', cursive !important;
}

/* Color Palette */
